import React from "react";
import Page from "./../../components/Page";
import Section from "./../../components/Section";
import SEO from "./../../components/SEO";
import Title from "../../components/releaseNotes/Title";

function Previous() {
  return (
    <Page>
      <SEO title="Release Notes - Previous | OHIF" />
      <Section className="max-w-6xl mx-auto">
        <main class="container mx-auto p-4 mt-8">
          <section>
            <Title
              title="OHIF Viewer v3.6 - Official Version 3 Release"
              secondary="June 2023"
            />
            <h2 class="text-xl font-semibold">New Features:</h2>
            <ul class="list-disc pl-8">
              <li>
                DICOM Radiotherapy Structure Sets: Enhancement of DICOM RTSTRUCT
                rendering pipeline to better integrate with other segmentation
                types.
              </li>
              <li>
                Slide Microscopy: Slide microscopy code updated with the latest
                technologies from the DICOM Microscopy Library and SLIM Viewer.
              </li>
              <li>
                DICOM Uploader: New feature to easily upload DICOM files
                directly from the viewer to their PACS systems over DICOMWeb.
              </li>
              <li>
                Cornerstone DICOM Image Loader Migrated to TypeScript:
                Transition to the new TypeScript-based DICOM Image Loader
                library.
              </li>
              <li>
                Cornerstone3D 1.0 Release: Announcement of Cornerstone3D
                reaching version 1.0, indicating its readiness for production
                use.
              </li>
            </ul>
          </section>

          <section>
            <Title
              title="OHIF Viewer v3.5 - Docker Build"
              secondary="May 2023"
            />

            <p>
              This update represents a minor release that is primarily focused
              on enhancing the development environment of the OHIF Viewer. It
              achieves this by integrating Docker build support, which is
              essential for streamlining the deployment process and ensuring
              consistent environments. Additionally, in an effort to optimize
              the development workflow, this release takes care of pushing
              changes to the master branch. Furthermore, it strategically splits
              the master branch from the release branch. This separation is
              crucial as it allows the developers to work more efficiently on
              the ongoing developments in the master branch, while
              simultaneously ensuring that the release branch remains stable and
              well-maintained. Such an approach underlines the commitment to
              both innovation and reliability.
            </p>
          </section>

          <section>
            <Title
              title="OHIF Viewer v3.4 - Enhanced Hanging Protocols"
              secondary="April 2023"
            />
            <ul class="list-disc pl-8">
              <li>
                New Viewport State Preservation: Enhancements in state
                synchronization in OHIF Viewer for a seamless experience when
                switching between Multiplanar Reformatting (MPR) and other
                views.
              </li>
              <li>
                Enhanced Hanging Protocol Matching: Improved hanging protocols
                for a faster, more user-friendly experience.
              </li>
              <li>
                Customizable Context Menu: Expansion of context menu options
                allowing for greater customization and addition of sub-menus.
              </li>
              <li>
                UI/UX Improvements: Revamped viewport header design and the
                addition of double-click functionality to maximize viewport.
              </li>
            </ul>
          </section>

          <section>
            <Title
              title="OHIF Viewer v3.3 - Segmentation Support"
              secondary="November 2022"
            />
            <ul class="list-disc pl-8">
              <li>
                3D Segmentation: Segmentations are natively loaded and rendered
                in 3D. The UI includes various interaction tools and rendering
                display preferences. Segmentation creation and editing tools are
                in development.
              </li>
              <li>
                Fast and Optimized Multiplanar Reconstruction (MPR): The viewer
                now supports MPR visualization of volumes and segmentations,
                leading to significantly reduced memory footprint and improved
                performance.
              </li>
              <li>
                New Collapsible Side Panels: The OHIF Viewer has redesigned side
                panels to be more compact and user friendly.
              </li>
              <li>
                Context-aware Drag and Drop via Hanging Protocols: The viewer
                now allows a more seamless experience when dragging and dropping
                thumbnails.
              </li>
              <li>
                New Tools: Two new tools have been added: Reference Lines and
                Stack Synchronizations.
              </li>
            </ul>
          </section>

          <section>
            <Title
              title="OHIF Viewer v3.2 - Mode Gallery & TMTV Mode"
              secondary="August 2022"
            ></Title>
            <ul class="list-disc pl-8">
              <li>
                New Total Metabolic Tumor Volume (TMTV) Workflow: This new mode
                includes high-performance rendering of volumetric data in ten
                distinct viewports, fusion of two series with adjustable
                colormaps, synchronization of the viewports for both camera and
                VOI settings, jump-to-click capability to synchronize navigation
                in all viewports, and support for exporting results.
              </li>
              <li>
                OHIF Workflow Mode Gallery: This new feature is a one-stop shop
                for users to find, install, and use OHIF modes and share
                functionality with the community. The gallery is continuously
                updated with community-submitted modes.
              </li>
            </ul>
          </section>

          <section>
            <Title
              title="OHIF Viewer v3.1 - Cornerstone3D Integration"
              secondary="July 2022"
            />
            <ul class="list-disc pl-8">
              <li>
                Cornerstone3D Integration: OHIF v3.1 has deprecated the legacy
                Cornerstone.js extension and replaced all functionality with
                Cornerstone3D. This includes updating the Measurement Tracking
                workflow mode.
              </li>
              <li>
                GPU Accelerated 2D Rendering: The Cornerstone3D rendering engine
                now supports WebGL 2D textures for large images, increasing the
                interaction speed significantly compared to the legacy
                Cornerstone.js engine.
              </li>
              <li>
                Upgraded Hanging Protocol Engine: The OHIF Hanging Protocol
                Engine has been updated for increased flexibility and ease of
                writing protocols. This includes the ability to position
                viewports in any non-grid layout and specify viewport
                configurations such as colormap, initial VOI, initial image to
                render, orientation, and more.
              </li>
            </ul>
          </section>

          <section>
            <Title
              title="OHIF Viewer v3.0 Public Beta Launch"
              secondary="September 2021"
            />
            <ul class="list-disc pl-8">
              <li>
                UI has been completely redesigned with modularity and workflow
                modes in mind.
              </li>
              <li>New UI components have been built with Tailwind CSS</li>
              <li>Addition of workflow modes</li>
              <li>
                Often, medical imaging use cases involve lots of specific
                workflows that reuse functionalities. We have added the
                capability of workflow modes, that enable people to customize
                user interface and configure application for specific workflow.
              </li>
              <li>
                The idea is to reuse the functionalities that extensions provide
                and create a workflow. Brain segmentation workflow is different
                from prostate segmentation in UI for sure; however, they share
                the segmentation tools that can be re-used.
              </li>
              <li>
                Our vision is that technical people focus on developing
                extensions which provide core functionalities, and experts to
                build modes by picking the appropriate functionalities from each
                extension.
              </li>
            </ul>
          </section>
        </main>
      </Section>
    </Page>
  );
}

export default Previous;
